/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import './footer.css'
import { FiFacebook, FiInstagram, FiTwitter } from 'react-icons/fi'

const Footer = () => {
	return (
		<footer>
			<a className='footer__logo' href='#'>
				Lefaktar
			</a>
			<ul className='permalinks'>
				<li>
					<a className='permalink__item-link' href='#'>
						Home
					</a>
				</li>
				<li>
					<a className='permalink__item-link' href='#about'>
						About
					</a>
				</li>
				<li>
					<a className='permalink__item-link' href='#experience'>
						Experience
					</a>
				</li>
				<li>
					<a className='permalink__item-link' href='#services'>
						Services
					</a>
				</li>
				<li>
					<a className='permalink__item-link' href='#portfolio'>
						Portfolio
					</a>
				</li>
				<li>
					<a className='permalink__item-link' href='#testimonials'>
						Testimonials
					</a>
				</li>
				<li>
					<a className='permalink__item-link' href='#contact'>
						Contact
					</a>
				</li>
			</ul>

			<div className='footer__socials'>
				<a
					className='footer__social-link'
					href='https://facebook.com'
					target='_blank'
				>
					<FiFacebook />
				</a>
				<a
					className='footer__social-link'
					href='https://instagram.com'
					target='_blank'
				>
					<FiInstagram />
				</a>
				<a
					className='footer__social-link'
					href='https://twitter.com'
					target='_blank'
				>
					<FiTwitter />
				</a>
			</div>

			<div className='footer__copyright'>
				<small>&copy; Lefaktar. All rights reserved.</small>
			</div>
		</footer>
	)
}

export default Footer
