/* eslint-disable jsx-a11y/img-redundant-alt */
import './about.css'
import ME from '../../assets/mee.jpg'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'

const About = () => {
	return (
		<section id='about'>
			<h5>Get To Know</h5>
			<h2>About Me</h2>

			<div className='container about__container'>
				<div className='about__me'>
					<div className='about__me-image'>
						<img src={ME} alt='About Image' />
					</div>
				</div>
				<div className='about__content'>
					<div className='about__cards'>
						<article className='about__card'>
							<FaAward className='about__icon' />
							<h5>Experience</h5>
							<small>3+ Years Working</small>
						</article>
						<article className='about__card'>
							<FiUsers className='about__icon' />
							<h5>Clients</h5>
							<small>300+ Worldwide</small>
						</article>
						<article className='about__card'>
							<VscFolderLibrary className='about__icon' />
							<h5>Projects</h5>
							<small>30+ Completed</small>
						</article>
					</div>
					<p>
						Lorem ipsum dolor sit, consectetur. minima ea eos, quos et? Lorem
						ipsum dolor sit, consectetur. minima ea eos, quos et? Lorem ipsum
						dolor sit, consectetur. minima ea eos, quos et? Lorem ipsum dolor
						sit, consectetur. minima ea eos, quos et?
					</p>
					<a className='btn btn-primary' href='#contact'>
						Let's Talk
					</a>
				</div>
			</div>
		</section>
	)
}

export default About
