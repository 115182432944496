/* eslint-disable react/jsx-no-target-blank */
import './contact.css'
import { MdOutlineMail } from 'react-icons/md'
import { BsWhatsapp, BsMessenger } from 'react-icons/bs'
import { useRef } from 'react'
import emailjs from 'emailjs-com'

const Contact = () => {
	const form = useRef()

	const sendEmail = e => {
		e.preventDefault()

		emailjs
			.sendForm(
				'service_9ejyr43',
				'template_7yesrfk',
				form.current,
				'wZEWGnPaL7htLJHSL'
			)
			.then(
				result => {
					console.log(result.text)
				},
				error => {
					console.log(error.text)
				}
			)
		e.target.reset()
	}

	return (
		<section id='contact'>
			<h5>Get in Touch</h5>
			<h2>Contact Me</h2>

			<div className='container contact__container'>
				<div className='contact__options'>
					<article className='contact__option'>
						<MdOutlineMail className='contact__option-icon' />
						<h4>Email</h4>
						<h5>lefaktar@gmail.com</h5>
						<a
							className='contact__link'
							href='mailto:lefaktar@gmail.com'
							target='_blank'
						>
							Send a message
						</a>
					</article>
					<article className='contact__option'>
						<BsMessenger className='contact__option-icon' />
						<h4>Messenger</h4>
						<h5>Konstantin</h5>
						<a
							className='contact__link'
							href='https://m.me/100001263611190/'
							target='_blank'
						>
							Send a message
						</a>
					</article>
					<article className='contact__option'>
						<BsWhatsapp className='contact__option-icon' />
						<h4>WhatsApp</h4>
						<h5>Konstantin</h5>
						<a
							className='contact__link'
							href='https://api.whatsapp.com/send?phone=79626812713'
							target='_blank'
						>
							Send a message
						</a>
					</article>
				</div>
				{/* END OF CONTACT OPTIONS */}
				<form ref={form} onSubmit={sendEmail}>
					<input
						autocomplete='off'
						type='text'
						name='name'
						placeholder='Your Full Name'
						required
					/>
					<input
						autocomplete='off'
						type='email'
						name='email'
						placeholder='Your Email'
						required
					/>
					<textarea
						name='message'
						rows='7'
						placeholder='Your Message'
						required
					></textarea>
					<button type='submit' className='btn btn-primary'>
						Send Message
					</button>
				</form>
			</div>
		</section>
	)
}

export default Contact
