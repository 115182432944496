import { BsLinkedin, BsGithub, BsTelegram } from 'react-icons/bs'

const HeaderSocial = () => {
	return (
		<div className='header__socials'>
			<a
				href='https://linkedin.com/in/konstantin-okunev-11a9a025a/'
				target='_blank'
				rel='noreferrer'
			>
				<BsLinkedin />
			</a>
			<a
				href='https://github.com/Lefaktar-coder'
				target='_blank'
				rel='noreferrer'
			>
				<BsGithub />
			</a>
			<a href='https://t.me/lefaktar' target='_blank' rel='noreferrer'>
				<BsTelegram />
			</a>
		</div>
	)
}

export default HeaderSocial
